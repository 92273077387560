@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%; /* #020817 */
        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;
        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;
        --primary: 215 100% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;
        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;
        --muted: 210 40% 96.1%; /* #F1F5F9 */
        --muted-foreground: 215.4 16.3% 46.9%; /* #64748B */
        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;
        --destructive: 0, 93%, 95%;
        --destructive-foreground: 0, 63%, 50%;
        --border: 214.3 31.8% 93.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 221.2 83.2% 53.3%;
        --radius: 0.5rem;
        --warning: 33, 100%, 96%;
        --warning-foreground: 15, 79%, 34%;
        --chart-1: 12 76% 61%;
        --chart-2: 173 58% 39%;
        --chart-3: 197 37% 24%;
        --chart-4: 43 74% 66%;
        --chart-5: 27 87% 67%;
    }

    .dark {
        --background: 213 25% 11%;
        --foreground: 210 40% 98%;
        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;
        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;
        --primary: 217.2 91.2% 59.8%;
        --primary-foreground: 222.2 47.4% 11.2%;
        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;
        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;
        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;
        --destructive: 0, 63%, 31%, 0.8;
        --destructive-foreground: 0, 96%, 89%, 1;
        --border: 217.2 32.6% 30.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 224.3 76.3% 48%;
        --warning: 27, 96%, 61%, 0.2;
        --warning-foreground: 31, 97%, 72%, 1;
        --chart-1: 220 70% 50%;
        --chart-2: 160 60% 45%;
        --chart-3: 30 80% 55%;
        --chart-4: 280 65% 60%;
        --chart-5: 340 75% 55%;
    }
}

@layer base {
    * {
        @apply border-border;
        -webkit-font-smoothing: antialiased;
    }
    a {
        @apply text-primary;
    }
    a:hover {
        @apply underline;
    }
    body {
        @apply bg-background text-foreground;
        font-feature-settings: "rlig" 1, "calt" 1;
    }
}

@layer debug {
    :root {
        --background: hsl(0 0% 100%);
        --foreground: hsl(222.2 84% 4.9%);
        --card: hsl(0 0% 100%);
        --card-foreground: hsl(222.2 84% 4.9%);
        --popover: hsl(0 0% 100%);
        --popover-foreground: hsl(222.2 84% 4.9%);
        --primary: hsl(215 100% 98%);
        --primary-foreground: hsl(222.2 47.4% 1.2%);
        --secondary: hsl(210 40% 96.1%);
        --secondary-foreground: hsl(222.2 47.4% 11.2%);
        --muted: hsl(210 40% 96.1%);
        --muted-foreground: hsl(215.4 16.3% 46.9%);
        --accent: hsl(210 40% 96.1%);
        --accent-foreground: hsl(222.2 47.4% 11.2%);
        --destructive: hsl(0 84.2% 60.2%);
        --destructive-foreground: hsl(210 40% 98%);
        --border: hsl(214.3 31.8% 93.4%);
        --input: hsl(214.3 31.8% 91.4%);
        --ring: hsl(221.2 83.2% 53.3%);
    }
}

.Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    width: fit-content;
    @apply rounded-full bg-danger text-white text-xs font-bold px-2 py-0 -top-3 -right-12;
    /* Add in a blinking animation */
    animation: blinker 4s linear infinite;
}

/* Hack in text to the badge */
.Canny_BadgeContainer .Canny_Badge::before {
    content: "Updates!";
    color: white;
    font-size: 0.7em;
    width: fit-content;
    text-wrap: nowrap;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.shimmer {
    animation: shimmer 5s infinite linear;
}

.shimmer-delayed {
    animation: shimmer 5s infinite 4s linear;
}
